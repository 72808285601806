<template>
  <div class="container-fluid">
    <div class="row">
      <h1 style="color: black;">Participant Growth Dashboard</h1>
    </div>

    <div class="row" style="border-bottom: thin solid black; padding: 1%;">
      <h4 style="color: black;">Organization Hierarchy</h4>

      <div class="col-sm">
        <ParticipatingCountries
            @countryCode="changeCountry($event)"
            @countryName="updateCountryName($event)"
        />
      </div>
      <div class="col-sm">
        <div v-if="selectedCountry === 'US'">
          <ParticipatingStates
              @selectedState="changeState($event)"
              @stateName="updateStateName($event)"
          />
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingEsus @selectedEsu="changeEsu($event)" @esuCount="updateEsuCount($event)"/>
          <!--        <h6>Participating ESU Count: {{ esuCount }}</h6>-->
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingDistricts :id-esu="selectedEsu" @selectedDistrict="changeDistrict($event)"
                                  @districtCount="updateDistrictCount($event)"/>
          <!--        <h6>Participating District Count: {{ districtCount }}</h6>-->
        </div>
      </div>
      <div class="col-sm">
        <div v-if="showNebraskaHierarchy">
          <ParticipatingSchools :id-esu="selectedEsu" :id-district="selectedDistrict"
                                @schoolCount="updateSchoolCount($event)" @selectedSchool="changeSchool($event)"/>
          <!--        <h6>Participating School Count: {{ schoolCount }}</h6>-->
        </div>
      </div>
<!--      <div>-->
<!--        <button id="reset" class="button btn-primary" @click="clearResults">Reset Selected Organizations</button>-->
<!--      </div>-->
    </div>

    <div class="row mt-2">
      <h5 style="color: black;">Date Range</h5>
      <div class="col-sm">
        <label>Start Date</label>
        <DatePicker v-model="startDate" :enable-time-picker="false" textInput/>
      </div>
      <div class="col-sm">
        <label></label>
        <p>The date range you search for will filter results between the two dates.</p>
      </div>
      <div class="col-sm">
        <label>End Date</label>
        <DatePicker v-model="endDate" :minDate="minEndDate" :enable-time-picker="false" textInput/>
      </div>
    </div>


    <!--  Show growth Graphs -->
    <div class="flex-row">
      <div v-if="selectedCountry === 'ALL'">
        <AllParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
        />
      </div>

      <div v-else-if="countryOrState">
        <CountryParticipantGrowth
            :start-date="startDate"
            :end-date="endDate"
            :country-code="selectedCountry"
            :country-name="countryName"
        />
      </div>

      <div v-else-if="stateOrEsu">
        <StateParticipantGrowth
            :state-short="selectedState"
            :start-date="startDate"
            :end-date="endDate"
            :state-name="stateName"
        />
      </div>

      <div v-else-if="esuOrDistrict && selectedSchool === -1">
        <EsuParticipantGrowth
            :id-esu="selectedEsu"
            :start-date="startDate"
            :end-date="endDate"
        />

      </div>

      <div v-else-if="districtOrSchool">
        <DistrictParticipantGrowth
            :id-district="selectedDistrict"
            :start-date="startDate"
            :end-date="endDate"
        />
      </div>

      <div v-else>
        <SchoolParticipantGrowth
            :id-school="selectedSchool"
            :start-date="startDate"
            :end-date="endDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ParticipatingEsus from "@/components/Reporting/OrganizationDropdowns/ParticipatingEsus";
import {computed, onBeforeMount, ref} from 'vue';
import ParticipatingDistricts from "@/components/Reporting/OrganizationDropdowns/ParticipatingDistricts";
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";
import ParticipatingStates from "@/components/Reporting/OrganizationDropdowns/ParticipatingStates";
import StateParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/StateParticipantGrowth";
import DistrictParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/DistrictParticipantGrowth";
import DatePicker from "@vuepic/vue-datepicker";
import {subtractDaysFromDate} from "@/Helpers/DateHelper";
import EsuParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/EsuParticipantGrowth";
import SchoolParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/SchoolParticipantGrowth";
import ParticipatingCountries from "@/components/Reporting/OrganizationDropdowns/ParticipatingCountries";
import CountryParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/CountryParticipantGrowth";
import AllParticipantGrowth from "@/views/SuperUserViews/Reporting/ParticipantGrowth/AllParticipantGrowth";

export default {
  name: "ParticipatingOrganizations",
  components: {
    AllParticipantGrowth,
    CountryParticipantGrowth,
    ParticipatingCountries,
    SchoolParticipantGrowth,
    EsuParticipantGrowth,
    DistrictParticipantGrowth,
    StateParticipantGrowth,
    ParticipatingStates,
    ParticipatingSchools,
    ParticipatingDistricts,
    ParticipatingEsus,
    DatePicker
  },
  setup() {
    const startDate = ref(subtractDaysFromDate(90));
    const endDate = ref(new Date());
    const selectedEsu = ref(-1);
    const selectedDistrict = ref(-1);
    const selectedSchool = ref(-1);
    const selectedState = ref("-1");
    const selectedCountry = ref("-1");
    const esuCount = ref(0);
    const districtCount = ref(0);
    const schoolCount = ref(0);
    const stateName = ref("");
    const countryName = ref("");

    const minEndDate = computed(() => {
      let min_date = new Date(startDate.value.toDateString());
      let result = min_date.setDate(min_date.getDate() + 1);
      return new Date(result);
    })

    onBeforeMount(() => {
      setDefaultDates()
    })

    function setDefaultDates() {
      let tmp_date = new Date();
      let tmp_year = tmp_date.getFullYear();

      if (tmp_date.getMonth() >= 9) {
        tmp_year += 1;
      }
      endDate.value = new Date(tmp_year, 9, 1);
      startDate.value = new Date(tmp_year - 1, 9, 1);
    }

    function changeEsu(event) {
      selectedEsu.value = event;
      changeDistrict(-1);
    }

    function changeDistrict(event) {
      selectedDistrict.value = event;
      changeSchool(-1);
    }

    function changeSchool(event) {
      selectedSchool.value = event;
    }

    function changeState(event) {
      selectedState.value = event;
      changeEsu(-1);
    }

    function changeCountry(event) {
      selectedCountry.value = event;
      selectedState.value = "-1";
    }

    function updateEsuCount(event) {
      esuCount.value = event;
    }

    function updateDistrictCount(event) {
      districtCount.value = event;
    }

    function updateSchoolCount(event) {
      schoolCount.value = event;
    }

    function updateStateName(event) {
      stateName.value = event;
    }

    function updateCountryName(event) {
      countryName.value = event;
    }

    //
    // function clearResults() {
    //   selectedEsu.value = -1;
    //   selectedDistrict.value = -1;
    //   selectedSchool.value = -1;
    //   selectedState.value = "-1";
    //   selectedCountry.value = "-1";
    // }


    const showNebraskaHierarchy = computed(() => {
      return selectedState.value === 'NE';
    })

    const countryOrState = computed(() => {
      return selectedCountry.value !== '-1' && selectedState.value === '-1';
    })

    const stateOrEsu = computed(() => {
      return selectedState.value !== '-1' && selectedEsu.value === -1;
    })

    const esuOrDistrict = computed(() => {
      return selectedEsu.value !== -1 && selectedDistrict.value === -1;
    })

    const districtOrSchool = computed(() => {
      return selectedDistrict.value !== -1 && selectedSchool.value === -1;
    })


    return {
      selectedEsu,
      selectedDistrict,
      selectedSchool,
      selectedState,
      esuCount,
      districtCount,
      schoolCount,
      selectedCountry,
      countryOrState,
      esuOrDistrict,
      stateOrEsu,
      startDate,
      endDate,
      minEndDate,
      districtOrSchool,
      stateName,
      countryName,
      showNebraskaHierarchy,
      updateStateName,
      updateCountryName,
      changeCountry,
      updateEsuCount,
      updateDistrictCount,
      updateSchoolCount,
      changeSchool,
      changeEsu,
      changeDistrict,
      changeState,
      // clearResults
    }
  }
}
</script>

<style scoped>


</style>